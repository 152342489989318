<script>
import _ from 'lodash'
import {mapState} from "vuex";

export default {
  name: "customMenu",
  props: [
    "menu",
  ],
  methods: {
    goRouter(index, subIndex) {
      console.log('click', index, subIndex)
      this.$emit('click', index, subIndex)
    },
    concatPath(menu, parent) {
      if (!parent) {
        return menu.path
      }
      return parent.path + '/' + menu.path
    },
    tagMenuList(menuList, index, parent, parentIndex) {
      return menuList
      _.forEach(menuList, menu => {
        if (this.userRoutes[menu.path] === undefined) {
          menu.hidden = true
        } else {
          _.forEach(menu.children, subMenu => {
            if (_.indexOf(this.userRoutes[menu.path], '/' + subMenu.path) === -1) {
              subMenu.hidden = true
            }
          })
        }
      })
      return menuList
    },
    isShow(menu, index, parent, parentIndex) {
      return !menu.hidden
    },
    isPromoteFirstSubMenuItem(menu, index, parent, parentIndex) {
      return menu.promote
    },
    handlePromoteFirstSubMenuItem(h, menu, index, parent, parentIndex) {
      return this.generateMenu(h, this.menuChildren(menu, 0), 0, menu, index)
    },
    menuChildren(menu, index) {
      if (index !== undefined) {
        return menu.children[index]
      }
      return menu.children
    },
    menuHasChildren(menu) {
      return this.menuChildren(menu) // && this.menuChildren(menu).length > 0
    },
    generateMenu(h, menu, index, parent, parentIndex) {
      if (this.isPromoteFirstSubMenuItem(menu, index, parent, parentIndex)) {
        return this.handlePromoteFirstSubMenuItem(h, menu, index, parent, parentIndex)
      }

      if (this.menuHasChildren(menu)) {
        return this.renderSubMenu(h, menu, index, parent, parentIndex)
      }

      return this.renderMenuItem(h, menu, index, parent, parentIndex)
    },
    generate(h, menuList, parent, parentIndex) {
      return _.map(menuList, (menu, index) => {
        if (this.isShow(menu)) {
          return this.generateMenu(h, menu, index, parent, parentIndex)
        }
      })
    },
    renderMenuItem(h, menu, index, parent, parentIndex) {
      return h('a-menu-item', {
        attrs: {
          key: this.concatPath(menu, parent)
        },
        on: {
          click: () => {
            this.goRouter(parentIndex, index)
          }
        }
      }, menu.meta.title)
    },
    renderSubMenu(h, menu, index, parent, parentIndex) {
      return h('a-sub-menu', {
        attrs: {
          key: this.concatPath(menu, parent)
        },
        scopedSlots: {
          title: () => menu.meta.title
        },
      }, this.generate(h, this.menuChildren(menu), menu, index))
    },
  },
  render(h) {
    return h('a-menu', {
          attrs: {
            mode: 'inline',
            theme: 'dark'
          }
        },
        this.generate(h, this.tagMenuList(this.menu)))
  },
  computed: {
    userRoutes() {
      let hash = {}
      _.forEach(this.$store.getters.componentRoutes, route => {
        hash[route.path] = _.map(route.children, menu => {
          return menu.path
        })
      })
      console.log(hash)
      return hash
    }
  }
}
</script>

<style scoped>

</style>
